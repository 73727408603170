import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'
import { useTranslation } from 'react-i18next'
import { attemptRefreshTokens, logout } from '../features/auth/redux/authSlice'
import { useToastContext } from './Toast/ToastContext'

export default function SessionExpiration() {
  const { ToastOpen, ToastDestroy } = useToastContext()
  const accessTokenExpiresAt = useSelector(
    (state: RootState) => state.auth.sessionExpiresAt,
  )
  const refreshTokenExpiresAt = useSelector(
    (state: RootState) => state.auth.refreshTokenExpiresAt,
  )

  const logoutTimeoutRef = useRef<number | ReturnType<typeof setTimeout>>()
  const refreshTimeoutRef = useRef<number | ReturnType<typeof setTimeout>>()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('auth')

  useEffect(() => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current)
    }
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current)
    }

    if (accessTokenExpiresAt) {
      const logoutAfter = accessTokenExpiresAt - Date.now()
      const refreshAfter = logoutAfter - 60 * 1000
      const canRefresh = refreshTokenExpiresAt && (refreshTokenExpiresAt - Date.now()) > 1000

      console.log(`SessionExpiration logoutAfter=${logoutAfter} refreshAfter=${refreshAfter} canRefresh=${canRefresh}`)

      if (!canRefresh || logoutAfter < -60 * 60 * 1000) {
        // If cannot refresh or one hour of inactivity
        console.log('SessionExpiration logout immediately', canRefresh, logoutAfter)
        dispatch(logout())
      }
      else {
        if (logoutAfter < 0) {
          console.log(`SessionExpiration reopening logoutAfter=${logoutAfter}`)
          // In most cases it happens on reopening a browser after a long delay
          // refresh immediately
          dispatch(attemptRefreshTokens())
        } 
        else {
          // Timeout to refresh if possible
          refreshTimeoutRef.current = setTimeout(
            () => {
              dispatch(attemptRefreshTokens())
            },
            refreshAfter < 5000 ? 0 : refreshAfter,
          )
  
          // Timeout to logout
          logoutTimeoutRef.current = setTimeout(
            () => {
              ToastOpen({
                message: t('Your session has expired. Please log in again.'),
                type: 'error',
              })
  
              dispatch(logout())
            },
            logoutAfter < 5000 ? 5000 : logoutAfter,
          )
        }
      }      
    }

    return () => {
      if (logoutTimeoutRef.current) {
        console.log('CLEARED LOGOUT')
        clearTimeout(logoutTimeoutRef.current)
      }
      if (refreshTimeoutRef.current) {
        console.log('CLEARED REF')
        clearTimeout(refreshTimeoutRef.current)
      }
    }
  }, [accessTokenExpiresAt])

  return <></>
}
